<template>
  <b-card>
    <div class="text-center">
      <a
        href="#"
        class="btn btn-primary text-center w-100 h-100"
      > Zaloguj się do swojego banku </a>
    </div>
  </b-card>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
  },

}
</script>

<style>

</style>
